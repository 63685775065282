$(function () {
  var ua = navigator.userAgent;
  if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
    $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
  } else {
    $('head').prepend('<meta name="viewport" content="width=1366">');
  }
});

$(function () {
  var $elem = $('.posSwitch');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.prependTo(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});


function moveAnimation(){

    var randomElm = $(".momoArea");//親要素取得
    var randomElmChild = $(randomElm).children();//親の子要素を取得
    if(!$(randomElm).hasClass("play")){	//親要素にクラス名playが付いてなければ処理をおこなう
      randomAnime();	
    }
    
    function randomAnime(){
      $(randomElm).addClass("play");//親要素にplayクラスを付与
      var rnd = Math.floor(Math.random() * randomElmChild.length); //配列数からランダム数値を取得
      var moveData = "fadeUp";//アニメーション名＝CSSのクラス名を指定
      $(randomElmChild[rnd]).addClass(moveData);//アニメーションのクラスを追加
      randomElmChild.splice(rnd,1);//アニメーション追加となった要素を配列から削除
      if(randomElmChild.length == 0 ){//配列の残りがあるか確認
        $(randomElm).removeClass("play");//なくなった場合は親要素のplayクラスを削除
      }else{
        setTimeout(function(){randomAnime();},200);	//0.5秒間隔でアニメーションをスタートさせる。※ランダムのスピード調整はこの数字を変更させる	
      }
      
    }
    

  }
  
    var animeFlag = true;
  
    $(window).on('load', function(){
      moveAnimation();
    });

    $(function() {
      var $btn = $('.c_btn');
      var DURATION = 50;
      function fadeOutMenu(){
        $btn.removeClass('is-active')
          .next('.c_menu')
          .stop()
          .slideUp(DURATION);
      }
      function toggleMenu(){
        var $self = $(this); //thisにはクリックした時の要素が入る
        //要素が.is-activeを持っていない場合
        if(!$self.hasClass('is-active')){
          fadeOutMenu();
        }
        //クリックした要素を表示させる
        $self.toggleClass('is-active')
          .next('.c_menu')
          .stop().slideToggle(DURATION);
      }
    
      $btn.on('click', toggleMenu);
     
    //別の場所をクリックすると閉じる処理
      $(document).on('click touchend', function(event) {
      if (!$(event.target).closest('body').length) {
        // ここに処理;
        fadeOutMenu();//関数呼びだし
      }
    });
    });


    $(function() {
      var nav = $('.navArea');
      //表示位置
      var navTop = nav.offset().top+300;
      //ナビゲーションの高さ（シャドウの分だけ足してます）
      var navHeight = nav.height()+10;
      var showFlag = false;
      nav.css('top', -navHeight+'px');
      //ナビゲーションの位置まできたら表示
      $(window).on('scroll', function () {
          var winTop = $(this).scrollTop();
          if (winTop >= navTop) {
              if (showFlag == false) {
                  showFlag = true;
                  nav
                      .addClass('fixed')
                      .stop().animate({'top' : '0px'}, 200);
              }
          } else if (winTop <= navTop) {
              if (showFlag) {
                  showFlag = false;
                  nav.stop().animate({'top' : -navHeight+'px'}, 200, function(){
                      nav.removeClass('fixed');
                  });
              }
          }
      });
  });

  $(function() {
    let box = $('.side_menu'),
        boxHeight = box.innerHeight(),
        boxWrapper = $('.lower_contentArea'),
        boxWrapperHeight = boxWrapper.innerHeight(),
        boxWrapperTop = boxWrapper.offset().top,
        boxWrapperBottom = boxWrapperTop + boxWrapperHeight,
        footerPos =  $('footer').offset().top,
        wh = $(window).innerHeight(),
        marginTop = 90;
    
    $(window).on('scroll', function() {
        let value = $(this).scrollTop();
        if ( value > boxWrapperBottom - boxHeight - marginTop) {
            box.css({
                'top': boxWrapperBottom - boxHeight - value,
                'position': 'fixed'
            });
        } else if ( value > boxWrapperTop - marginTop) {
            box.css({
                'top': marginTop,
                'position': 'fixed'
            });
        }  else if ( value+wh >= (footerPos+10)) {
          box.css({
            'top': 'auto',
            'bottom': 50,
            'position': 'absolute'
          });
      } 
        else {
            box.css({
              'top': marginTop,
                'position': 'absolute'
            });
        }
    });
});
